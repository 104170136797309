import Vue from "vue";
import App from "./App.vue";

// Firebase
import { initializeApp, getApp } from "@firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"

//Bootstarp
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@comlinedev/bootstrap4/dist/css/bootstrap.css";
Vue.use(BootstrapVue);

import "./plugins/fontawesome";

import { convertDate, convertResponse } from "./plugins/filters";


const firebaseConfig = {
  apiKey: "AIzaSyDygGnAGf_IVB23dR8cZcTCfI--T9-zMGE",
  authDomain: "clgmbh-ustid-checker.firebaseapp.com",
  databaseURL: "https://clgmbh-ustid-checker.firebaseio.com",
  projectId: "clgmbh-ustid-checker",
  storageBucket: "clgmbh-ustid-checker.appspot.com",
  messagingSenderId: "438370152850",
  appId: "1:438370152850:web:4ddbc3f372a54160ab514b",
};

const app = initializeApp(firebaseConfig);

const functions = getFunctions(getApp(), "europe-west3");
const auth = getAuth();
const store = getStorage();
const firestore = getFirestore();

if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectStorageEmulator(store, 'localhost', 9199)
}

import router from "./plugins/router";

Vue.config.productionTip = false;

getAuth(app).onAuthStateChanged(() => {
  Vue.filter("convertDate", convertDate);
  Vue.filter("convertResponse", convertResponse);
  new Vue({
    render: (h) => h(App),
    router,
    beforeMount() {
      Vue.prototype.$auth = auth;
      Vue.prototype.$storage = store;
      Vue.prototype.$functions = functions;
      Vue.prototype.$firestore = firestore;
    },
  }).$mount("#app");
});