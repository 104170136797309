var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[(_vm.isLoading === false)?_c('div',[_c('b-card',{staticClass:"mb-4"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.displayname))])]),_c('p',[_c('strong',[_vm._v("Kundennummer:")]),_vm._v(" "+_vm._s(_vm.customernumber))]),(true)?_c('div',[_c('p',[_c('strong',[_vm._v("Abweichende Daten zur Prüfung")]),_c('br'),_vm._v(" Sind die Felder gefüllt, wird die Prüfung mit diesen abweichenden Daten durchgeführt. ")]),_c('div',{staticClass:"w-25"},[_c('b-form-group',{attrs:{"label":"Firmenname"}},[_c('b-form-input',{model:{value:(_vm.verificationData['Firmenname']),callback:function ($$v) {_vm.$set(_vm.verificationData, 'Firmenname', $$v)},expression:"verificationData['Firmenname']"}})],1),_c('b-form-group',{attrs:{"label":"Strasse"}},[_c('b-form-input',{model:{value:(_vm.verificationData['Strasse']),callback:function ($$v) {_vm.$set(_vm.verificationData, 'Strasse', $$v)},expression:"verificationData['Strasse']"}})],1),_c('b-form-group',{attrs:{"label":"PLZ"}},[_c('b-form-input',{model:{value:(_vm.verificationData['PLZ']),callback:function ($$v) {_vm.$set(_vm.verificationData, 'PLZ', $$v)},expression:"verificationData['PLZ']"}})],1),_c('b-form-group',{attrs:{"label":"Ort"}},[_c('b-form-input',{model:{value:(_vm.verificationData['Ort']),callback:function ($$v) {_vm.$set(_vm.verificationData, 'Ort', $$v)},expression:"verificationData['Ort']"}})],1)],1)]):_vm._e()]),(_vm.verificationResults.length > 0)?_c('b-card',{attrs:{"body-class":"p-0"}},[_c('b-table',{staticClass:"m-0",attrs:{"striped":"","items":_vm.verificationResults,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(dateTime)",fn:function(data){return [_c('fa-icon',{staticClass:"mr-2",class:_vm.statusClass(
                data.item.Erg_Name,
                data.item.Erg_Str,
                data.item.Erg_PLZ,
                data.item.Erg_Ort
              ),attrs:{"icon":[
              'fas',
              _vm.statusIcon(
                data.item.Erg_Name,
                data.item.Erg_Str,
                data.item.Erg_PLZ,
                data.item.Erg_Ort
              ),
            ]}}),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("convertDate")(data.item.Datum))+" um "+_vm._s(data.item.Uhrzeit)+" Uhr")])]}},{key:"cell(details)",fn:function(row){return [_c('b-button',{staticStyle:{"white-space":"nowrap","float":"right"},attrs:{"size":"sm"},on:{"click":row.toggleDetails}},[_vm._v(" "+_vm._s(row.detailsShowing ? "Ausblenden" : "Anzeigen")+" ")])]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('p',[_vm._v(" Rückmeldung BZSt.: "+_vm._s(_vm._f("convertResponse")(row.item.ErrorCode))+" "),_c('b',[_vm._v("(Code: "+_vm._s(row.item.ErrorCode)+")")])]),_c('div',[_vm._v("USt.-ID: "+_vm._s(row.item.UstId_2))]),_c('div',[_vm._v("Firmenname: "+_vm._s(row.item.Firmenname)+" "),(row.item.Erg_Name !== '')?_c('strong',[_vm._v("("+_vm._s(row.item.Erg_Name)+")")]):_vm._e()]),_c('div',[_vm._v("Strasse: "+_vm._s(row.item.Strasse)+" "),(row.item.Erg_Str !== '')?_c('strong',[_vm._v("("+_vm._s(row.item.Erg_Str)+")")]):_vm._e()]),_c('div',[_vm._v("PLZ: "+_vm._s(row.item.PLZ)+" "),(row.item.Erg_PLZ !== '')?_c('strong',[_vm._v("("+_vm._s(row.item.Erg_PLZ)+")")]):_vm._e()]),_c('div',[_vm._v("Ort: "+_vm._s(row.item.Ort)+" "),(row.item.Erg_Ort !== '')?_c('strong',[_vm._v("("+_vm._s(row.item.Erg_Ort)+")")]):_vm._e()]),(row.item.Gueltig_ab !== '')?_c('div',[_vm._v("Gültig von: "+_vm._s(row.item.Gueltig_ab))]):_vm._e(),(row.item.Gueltig_bis !== '')?_c('div',[_vm._v("Gültig bis: "+_vm._s(row.item.Gueltig_bis))]):_vm._e()])]}}],null,false,2010684395)})],1):_vm._e()],1):_c('div',[_c('b-card',{staticClass:"mb-4"},[_c('p',[_c('b-skeleton',{attrs:{"width":"30%"}}),_c('b-skeleton',{attrs:{"width":"25%"}}),_c('b-skeleton',{attrs:{"width":"20%"}})],1),_c('p',[_c('b-skeleton',{attrs:{"width":"15%"}})],1),_c('b-skeleton',{attrs:{"width":"30%"}})],1),_c('b-card',{attrs:{"body-class":"p-0"}},[_c('b-skeleton-table',{staticClass:"mb-0",attrs:{"rows":5,"columns":1,"table-props":{ striped: true }}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }