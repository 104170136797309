<template>
  <div class="mb-4">
    <b-input-group class="mb-4">
      <b-form-input
        v-model="filter"
        type="search"
        placeholder="Suche"
      ></b-form-input>
      <b-input-group-append>
        <b-button v-on:click="filter = ''" variant="danger"><fa-icon :icon="['fas','times']" /></b-button>
      </b-input-group-append>
    </b-input-group>
    <b-card body-class="p-0">
      <b-table :filter="filter" striped :items="companies" :fields="fields" :sort-by.sync="sortBy" class="m-0" v-if="companies.length > 0">
        <template #cell(customernumber)="data">
          <router-link :to="{ name: 'HistoryResultView', params: { id: data.item.customernumber } }">{{ data.item.customernumber }}</router-link>
        </template>

        <template #cell(displayname)="data">
          <router-link :to="{ name: 'HistoryResultView', params: { id: data.item.customernumber } }">{{ data.item.displayname }}</router-link>
        </template>
      </b-table>
      <b-skeleton-table
        v-else
        :rows="5"
        :columns="2"
        :table-props="{ striped: true }"
        class="m-0"
      ></b-skeleton-table>
    </b-card>
  </div>
</template>

<script>
import { collection, onSnapshot } from "firebase/firestore";
export default {
  name: 'HistoryView',
  methods: {
    fetchData() {
      onSnapshot(collection(this.$firestore, "companies"), (querySnapshot) => {
        querySnapshot.forEach((doc) => {
            this.companies.push(doc.data());
        });
      });
    }
  }, 
  async mounted() {
    await this.fetchData()
  },
  data() {
    return {
      sortBy: 'displayname',
      filter: null,
      fields: [
        {
          key: 'displayname',
          label: 'Firma',
          sortable: true
        },
        {
          key: 'customernumber',
          label: 'Kundennummer',
          sortable: true
        }
      ],
      companies: []
    }
  }
}
</script>

<style>
table th {
  border-top: 0 !important;
}
</style>