<template>
  <div class="mb-4">
    <div v-if="isLoading === false">
      <b-card class="mb-4">
        <p><strong>{{ displayname }}</strong></p>
        <p><strong>Kundennummer:</strong> {{ customernumber }}</p>
        <div v-if="true">
          <p>
            <strong>Abweichende Daten zur Prüfung</strong><br />
            Sind die Felder gefüllt, wird die Prüfung mit diesen abweichenden Daten durchgeführt.
          </p>
          <div class="w-25">
            <b-form-group label="Firmenname">
              <b-form-input v-model="verificationData['Firmenname']"></b-form-input>
            </b-form-group>
            <b-form-group label="Strasse">
              <b-form-input v-model="verificationData['Strasse']"></b-form-input>
            </b-form-group>
            <b-form-group label="PLZ">
              <b-form-input v-model="verificationData['PLZ']"></b-form-input>
            </b-form-group>
            <b-form-group label="Ort">
              <b-form-input v-model="verificationData['Ort']"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </b-card>

      <b-card body-class="p-0" v-if="verificationResults.length > 0">
        <b-table
          striped
          :items="verificationResults"
          :fields="fields"
          class="m-0"
        >
          <template #cell(dateTime)="data">
            <fa-icon
              :icon="[
                'fas',
                statusIcon(
                  data.item.Erg_Name,
                  data.item.Erg_Str,
                  data.item.Erg_PLZ,
                  data.item.Erg_Ort
                ),
              ]"
              :class="
                statusClass(
                  data.item.Erg_Name,
                  data.item.Erg_Str,
                  data.item.Erg_PLZ,
                  data.item.Erg_Ort
                )
              "
              class="mr-2"
            />
            <span style="white-space: nowrap"
              >{{ data.item.Datum | convertDate }} um
              {{ data.item.Uhrzeit }} Uhr</span
            >
          </template>

          <template #cell(details)="row">
            <b-button
              size="sm"
              @click="row.toggleDetails"
              style="white-space: nowrap; float: right"
            >
              {{ row.detailsShowing ? "Ausblenden" : "Anzeigen" }}
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <p>
                Rückmeldung BZSt.: {{ row.item.ErrorCode | convertResponse }} <b>(Code: {{ row.item.ErrorCode }})</b>
              </p>
              <div>USt.-ID: {{ row.item.UstId_2 }}</div>
              <div>Firmenname: {{ row.item.Firmenname }} <strong v-if="row.item.Erg_Name !== ''">({{ row.item.Erg_Name }})</strong></div>
              <div>Strasse: {{ row.item.Strasse }} <strong v-if="row.item.Erg_Str !== ''">({{ row.item.Erg_Str }})</strong></div>
              <div>PLZ: {{ row.item.PLZ }} <strong v-if="row.item.Erg_PLZ !== ''">({{ row.item.Erg_PLZ }})</strong></div>
              <div>Ort: {{ row.item.Ort }} <strong v-if="row.item.Erg_Ort !== ''">({{ row.item.Erg_Ort }})</strong></div>
              <div v-if="row.item.Gueltig_ab !== ''">Gültig von: {{ row.item.Gueltig_ab }}</div>
              <div v-if="row.item.Gueltig_bis !== ''">Gültig bis: {{ row.item.Gueltig_bis }}</div>
            </b-card>
          </template>
        </b-table>
      </b-card>
    </div>
    <div v-else>
      <b-card class="mb-4">
        <p>
          <b-skeleton width="30%"></b-skeleton>
          <b-skeleton width="25%"></b-skeleton>
          <b-skeleton width="20%"></b-skeleton>
        </p>
        <p><b-skeleton width="15%"></b-skeleton></p>
        <b-skeleton width="30%"></b-skeleton>
      </b-card>
      <b-card body-class="p-0">
        <b-skeleton-table
          :rows="5"
          :columns="1"
          :table-props="{ striped: true }"
          class="mb-0"
        ></b-skeleton-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { doc, getDoc, updateDoc, collection, onSnapshot, query, where, orderBy } from "firebase/firestore";

export default {
  name: "HistoryResultView",
  computed: {
    dbId: function () {
      return this.$route.params.id;
    },
  },
  watch: {
    comment: {
      handler: _.debounce(function (newComment, oldComment) {
        if (newComment !== oldComment) {
          const docRef = doc(this.$firestore, "companies", this.dbId);

          updateDoc(docRef, {
            comment: newComment
          });
        }
      }, 500),
    },
    "verificationData.Firmenname": {
      handler: _.debounce(function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const docRef = doc(this.$firestore, "companies", this.dbId);

          updateDoc(docRef, {
            "verificationData.Firmenname": newVal
          });
        }
      }, 500),
    },
    "verificationData.Strasse": {
      handler: _.debounce(function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const docRef = doc(this.$firestore, "companies", this.dbId);

          updateDoc(docRef, {
            "verificationData.Strasse": newVal
          });
        }
      }, 500),
    },
    "verificationData.Ort": {
      handler: _.debounce(function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const docRef = doc(this.$firestore, "companies", this.dbId);

          updateDoc(docRef, {
            "verificationData.Ort": newVal
          });
        }
      }, 500),
    },
    "verificationData.PLZ": {
      handler: _.debounce(function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const docRef = doc(this.$firestore, "companies", this.dbId);

          updateDoc(docRef, {
            "verificationData.PLZ": newVal
          });
        }
      }, 500),
    },
  },
  methods: {
    fetchData: async function () {
      const docRef = doc(this.$firestore, "companies", this.dbId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        this.customernumber = docSnap.data().customernumber;
        this.displayname = docSnap.data().displayname;
        this.comment = docSnap.data().comment;
        this.verificationData["Strasse"] = docSnap.data()?.verificationData?.["Strasse"] || "";
        this.verificationData["PLZ"] = docSnap.data()?.verificationData?.["PLZ"] || "";
        this.verificationData["Ort"] = docSnap.data()?.verificationData?.["Ort"] || "";
        this.verificationData["Firmenname"] = docSnap.data()?.verificationData?.["Firmenname"] || "";

        onSnapshot(query(collection(this.$firestore, "verificationResults"), where("Kundennummer", "==", docSnap.data().customernumber), orderBy("timestamp", "desc")), (querySnapshot) => {
        querySnapshot.forEach((doc) => {
            this.verificationResults.push(doc.data());
        });
      });

        this.isLoading = false;
      } else {
        alert("No such document!");
      }
    },
    statusClass: function (erg_Name, erg_Str, erg_PLZ, erg_Ort) {
      return erg_Name === "A" &&
        erg_Str === "A" &&
        erg_PLZ === "A" &&
        erg_Ort === "A"
        ? "text-success"
        : "text-danger";
    },
    statusIcon: function (erg_Name, erg_Str, erg_PLZ, erg_Ort) {
      return erg_Name == "A" &&
        erg_Str === "A" &&
        erg_PLZ === "A" &&
        erg_Ort === "A"
        ? "check-circle"
        : "times-circle";
    },
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      isLoading: true,
      saved: null,
      fields: [
        {
          key: "dateTime",
          label: "Prüfungen",
        },
        {
          key: "details",
          label: "",
        },
      ],
      customernumber: "",
      displayname: "",
      comment: "",
      verificationData: {
        "Strasse": "",
        "PLZ": "",
        "Ort": "",
        "Firmenname": "",
      },
      verificationResults: [],
    };
  },
};
</script>