<template>
  <div>
    <NavigationComponent class="mb-4" />
    <b-container fluid>
      <router-view :ongoingList="ongoingList" :doneList="doneList" v-if="isLoading === false"></router-view>
      <SpinnerComponent v-else />
    </b-container>
  </div>
</template>

<script>
import NavigationComponent from '../components/NavigationComponent'
import SpinnerComponent from '../components/SpinnerComponent'
import { doc, onSnapshot } from "firebase/firestore";

export default {
  name: 'SignedInView',
  components: {
    NavigationComponent,
    SpinnerComponent
  },
  watch: {
    databaseList: function(list) {
      this.doneList = []
      this.ongoingList = []
      Object.entries(list).forEach(([key, value]) => {
        const entry = {
          key: key,
          value: value
        }
        if (value.hidden) {
          this.doneList.push(entry)
        } else {
          this.ongoingList.push(entry)
        }
      })
    }
  },
  async mounted() {
        onSnapshot(doc(this.$firestore, 'renewals'), (snapshot) => {
      if (snapshot.val() !== null) {
        this.databaseList = snapshot.val()
      } else {
        this.databaseList = {}
        this.ongoingList = []
        this.doneList = []
      }
      this.isLoading = false
    },)
  },
  data() {
    return {
      isLoading: false,
      databaseList: {},
      ongoingList: [],
      doneList: []
    }
  }
}
</script>

<style scoped>
.container-fluid {
  max-width: 1480px;
  background-color: #f8f9fa;
}
</style>