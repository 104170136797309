<template>
  <b-table striped :items="verificationResults" :fields="fields" class="m-0">
    <template #cell(dateTime)="row">
      <fa-icon :icon="['fas', statusIcon(row.item.Erg_Name, row.item.Erg_Str, row.item.Erg_PLZ, row.item.Erg_Ort)]" :class="statusClass(row.item.Erg_Name, row.item.Erg_Str, row.item.Erg_PLZ, row.item.Erg_Ort)" class="mr-2" />
      <span style="white-space: nowrap;">{{ row.item.Datum }} um {{ row.item.Uhrzeit }} Uhr</span>
    </template>

    <template #cell(Firmenname)="row">
      {{ row.item.Firmenname }}
    </template>

    <template #cell(UstId_2)="row">
      {{ row.item.UstId_2 }}
    </template>

    <template #cell(Kundennummer)="row">
      {{ row.item.Kundennummer }}
    </template>

    <template #cell(details)="row">
      <b-button size="sm" @click="row.toggleDetails" style="white-space: nowrap; float: right;">
        {{ row.detailsShowing ? 'Ausblenden' : 'Anzeigen'}} 
      </b-button>
    </template>

    <template #row-details="row">
      <b-card>
        <p>
          Rückmeldung BZSt.: {{ row.item.ErrorCode | convertResponse }} <b>(Code: {{ row.item.ErrorCode }})</b>
        </p>
        <div>USt.-ID: {{ row.item.UstId_2 }}</div>
        <div>Firmenname: {{ row.item.Firmenname }} <strong v-if="row.item.Erg_Name !== ''">({{ row.item.Erg_Name }})</strong></div>
        <div>Strasse: {{ row.item.Strasse }} <strong v-if="row.item.Erg_Str !== ''">({{ row.item.Erg_Str }})</strong></div>
        <div>PLZ: {{ row.item.PLZ }} <strong v-if="row.item.Erg_PLZ !== ''">({{ row.item.Erg_PLZ }})</strong></div>
        <div>Ort: {{ row.item.Ort }} <strong v-if="row.item.Erg_Ort !== ''">({{ row.item.Erg_Ort }})</strong></div>
        <div v-if="row.item.Gueltig_ab !== ''">Gültig von: {{ row.item.Gueltig_ab }}</div>
        <div v-if="row.item.Gueltig_bis !== ''">Gültig bis: {{ row.item.Gueltig_bis }}</div>
      </b-card>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'TableComponent',
  props: {
    verificationResults: Array
  },
  methods: {
    showResponse: function(code) {
      const response = this.$options.filters.convertResponse(code)
      console.log("ErrorCode:", code)
      return response.message
    },
    statusClass: function(erg_Name, erg_Str, erg_PLZ, erg_Ort) {
      return erg_Name === 'A' && erg_Str === 'A' && erg_PLZ === 'A' && erg_Ort === 'A' ? 'text-success' : 'text-danger'
      //return code === '200' || code === '201' || code === '216' || code === '218' || code === '219' ? 'text-success' : 'text-danger'
    },
    statusIcon: function(erg_Name, erg_Str, erg_PLZ, erg_Ort) {
      return erg_Name == 'A' && erg_Str === 'A' && erg_PLZ === 'A' && erg_Ort === 'A' ? 'check-circle' : 'times-circle'
      //return code === '200' || code === '201' || code === '216' || code === '218' || code === '219' ? 'check-circle' : 'times-circle'
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'dateTime',
          label: 'Prüfungszeitpunkt'
        },
        {
          key: 'Kundennummer',
          label: 'Kundennummer'
        },
        {
          key: 'Firmenname',
          label: 'Firmenname'
        },
        {
          key: 'UstId_2',
          label: 'USt.-ID'
        },
        {
          key: 'details',
          label: ''
        }
      ]
    }
  }
}
</script>

<style scoped>
.note-old {
  background-color: #e8f4f9;
  border: 1px solid #4089c9;
  padding: 10px;
  color: #4089c9;
  margin-bottom: 20px;
}
</style>