<template>
  <router-view id="app" class="w-100"></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
th {
  color: #5c5c5c;
}
</style>