import SignInView from '../../views/SignInView.vue'
import SignedInView from '../../views/SignedInView.vue'
import CheckerView from '../../views/CheckerView.vue'
import HistoryView from '../../views/HistoryView.vue'
import HistoryResultView from '../../views/HistoryResultView.vue'
import NotAllowed from "../../views/NotAllowedView.vue"

const routes = [
  { 
    path: '*',
    redirect: '/signin' 
  },
  { 
    path: '/', 
    redirect: '/signin' 
  },
  { 
    path: '/signin', 
    name: 'SignInView',
    component: SignInView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/token/:token",
    component: SignInView,
    name: "Token",
    meta: {
      requiresAuth: false,
    }, 
  },
  { 
    path: '/notallowed', 
    name: 'NotAllowed',
    component: NotAllowed,
    meta: {
      requiresAuth: true
    }
  },
  { 
    path: '/a/checker/', 
    component: SignedInView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '', 
        name: 'CheckerView',
        component: CheckerView
      },
      {
        path: '/a/history/', 
        name: 'HistoryView',
        component: HistoryView
      },
      {
        path: '/a/history/:id', 
        name: 'HistoryResultView',
        component: HistoryResultView
      }
   ] 
  }
]

export default routes