import moment from 'moment-timezone'

const convertDate = (date) => {
  const regex = RegExp(/(\d{2}\.){2}(\d{4})/)
  const isGermanDate = regex.test(date)
  return isGermanDate ? date : moment(date).format('DD.MM.YYYY')
}

const convertResponse = (code) => {
  let response = "";

  switch (code) {
    case "internal":
      response = 'Der Datensatz scheint ungültig zu sein. Bitte kontrolloiere alle Daten auf Vollständigkeit und Richtigkeit.'
      break;
    case "200":
      response = 'Die angefragte USt-IdNr. ist gültig.'
      break;
    case "201":
      response = 'Die angefragte USt-IdNr. ist gültig.'
      break;
    case "202":
      response = `
        Die angefragte USt-IdNr. ist ungültig. Sie ist nicht in der Unternehmerdatei des betreffenden EU-Mitgliedstaates registriert.<br>
        Hinweis: Ihr Geschäftspartner kann seine gültige USt-IdNr. bei der für ihn zuständigen Finanzbehörde in Erfahrung bringen. Möglicherweise muss er einen Antrag stellen, damit seine USt-IdNr. in die Datenbank aufgenommen wird.
      `
      break;
    case "203":
      response = 'Die angefragte USt-IdNr. ist ungültig. Sie ist erst ab dem ... gültig (siehe Feld "Gueltig_ab").'
      break;
    case "204":
      response = 'Die angefragte USt-IdNr. ist ungültig. Sie war im Zeitraum von ... bis ... gültig (siehe Feld "Gueltig_ab" und "Gueltig_bis").'
      break;
    case "205":
      response = 'Ihre Anfrage kann derzeit durch den angefragten EU-Mitgliedstaat oder aus anderen Gründen nicht beantwortet werden. Bitte versuchen Sie es später noch einmal. Bei wiederholten Problemen wenden Sie sich bitte an das Bundeszentralamt für Steuern - Dienstsitz Saarlouis.'
      break;
    case "206":
      response = 'Ihre deutsche USt-IdNr. ist ungültig. Eine Bestätigungsanfrage ist daher nicht möglich. Den Grund hierfür können Sie beim Bundeszentralamt für Steuern - Dienstsitz Saarlouis - erfragen.'
      break;
    case "207":
      response = 'Ihnen wurde die deutsche USt-IdNr. ausschliesslich zu Zwecken der Besteuerung des innergemeinschaftlichen Erwerbs erteilt. Sie sind somit nicht berechtigt, Bestätigungsanfragen zu stellen.'
      break;
    case "208":
      response = 'Für die von Ihnen angefragte USt-IdNr. läuft gerade eine Anfrage von einem anderen Nutzer. Eine Bearbeitung ist daher nicht möglich. Bitte versuchen Sie es später noch einmal.'
      break;
    case "209":
      response = 'Die angefragte USt-IdNr. ist ungültig. Sie entspricht nicht dem Aufbau der für diesen EU-Mitgliedstaat gilt. ( Aufbau der USt-IdNr. aller EU-Länder)'
      break;
    case "210":
      response = 'Die angefragte USt-IdNr. ist ungültig. Sie entspricht nicht den Prüfziffernregeln die für diesen EU-Mitgliedstaat gelten.'
      break;
    case "211":
      response = 'Die angefragte USt-IdNr. ist ungültig. Sie enthält unzulässige Zeichen (wie z.B. Leerzeichen oder Punkt oder Bindestrich usw.).'
      break;
    case "212":
      response = 'Die angefragte USt-IdNr. ist ungültig. Sie enthält ein unzulässiges Länderkennzeichen.'
      break;
    case "213":
      response = 'Die Abfrage einer deutschen USt-IdNr. ist nicht möglich.'
      break;
    case "214":
      response = 'Ihre deutsche USt-IdNr. ist fehlerhaft. Sie beginnt mit "DE" gefolgt von 9 Ziffern.'
      break;
    case "215":
      response = 'Ihre Anfrage enthält nicht alle notwendigen Angaben für eine einfache Bestätigungsanfrage (Ihre deutsche USt-IdNr. und die ausl. USt-IdNr.). Ihre Anfrage kann deshalb nicht bearbeitet werden.'
      break;
    case "216":
      response = `
        Ihre Anfrage enthält nicht alle notwendigen Angaben für eine qualifizierte Bestätigungsanfrage (Ihre deutsche USt-IdNr., die ausl. USt-IdNr., Firmenname einschl. Rechtsform und Ort).<br>
        Es wurde eine einfache Bestätigungsanfrage durchgeführt mit folgenden Ergebnis: Die angefragte USt-IdNr. ist gültig.
      `
      break;
    case "217":
      response = 'Bei der Verarbeitung der Daten aus dem angefragten EU-Mitgliedstaat ist ein Fehler aufgetreten. Ihre Anfrage kann deshalb nicht bearbeitet werden.'
      break;
    case "218":
      response = 'Eine qualifizierte Bestätigung ist zur Zeit nicht möglich. Es wurde eine einfache Bestätigungsanfrage mit folgendem Ergebnis durchgeführt: Die angefragte USt-IdNr. ist gültig.'
      break;
    case "219":
      response = 'Bei der Durchführung der qualifizierten Bestätigungsanfrage ist ein Fehler aufgetreten. Es wurde eine einfache Bestätigungsanfrage mit folgendem Ergebnis durchgeführt: Die angefragte USt-IdNr. ist gültig.'
      break;
    case "220":
      response = 'Bei der Anforderung der amtlichen Bestätigungsmitteilung ist ein Fehler aufgetreten. Sie werden kein Schreiben erhalten.'
      break;
    case "221":
      response = 'Die Anfragedaten enthalten nicht alle notwendigen Parameter oder einen ungültigen Datentyp. Weitere Informationen erhalten Sie bei den Hinweisen zum Schnittstelle - Aufruf.'
      break;
    case "999":
      response = 'Eine Bearbeitung Ihrer Anfrage ist zurzeit nicht möglich. Bitte versuchen Sie es später noch einmal.'
      break;
  }

  return response
}

export { convertDate, convertResponse }