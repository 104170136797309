<template>
  <div class="mb-4">
    <div class="d-flex justify-content-center mb-4">
      <b-form v-on:submit.prevent="checkNew">
        <b-input-group>
          <b-form-file placeholder="Datei auswählen" accept=".txt, .tsv, .csv" ref="uidFileInput" required v-on:change="readUidFile" />
          <b-input-group-append>
            <b-button ref="importButton" type="button" variant="primary" v-on:click="checkNew" :disabled="noOfUidsToCheck === 0">
              <span v-if="noOfUidsToCheck > 0">{{noOfUidsToCheck}} Datensätze prüfen</span>
              <span v-else>Prüfen</span>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </div>

    <b-card no-body v-if="negativeVerificationResults.length > 0 || positiveVerificationResults.length > 0">
      <b-tabs card>
        <b-tab :title="`Ungültig (${negativeVerificationResults.length})`" active v-if="negativeVerificationResults.length > 0">
          <a :href="downloadUrl" target="_blank" v-show="downloadUrl !== ''" class="btn btn-primary">Liste mit ungültigen Datensätzen herunterladen</a>
          <hr />
          <TableComponent :verificationResults="negativeVerificationResults" />
        </b-tab>
        <b-tab :title="`Gültig (${positiveVerificationResults.length})`" v-if="positiveVerificationResults.length > 0">
          <TableComponent :verificationResults="positiveVerificationResults" />
        </b-tab>
      </b-tabs>
    </b-card>

    <b-modal id="progress-modal" title="Prüfung läuft" hide-footer hide-header-close no-close-on-backdrop>
      <b-progress :max="noOfUidsToCheck" show-value animated>
        <b-progress-bar :value="noOfCheckedUids">
          {{ noOfCheckedUids }} / {{ noOfUidsToCheck }}
        </b-progress-bar>
      </b-progress>
    </b-modal>

    <b-modal id="error-modal" title="Fehler" hide-header-close ok-only no-close-on-backdrop>
      <p>Die bereitgestellten Daten aus der Datei sind fehlerhaft.</p>
      <ul>
        <li v-for="(error, index) in errors" :key="`error-${index}`">
          {{ error.msg }} in dem Datensatz für die Firma <strong>{{ error.data.Firmenname }}</strong>
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import Papa from 'papaparse'
import moment from 'moment-timezone'
import TableComponent from '../components/TableComponent'
import { httpsCallable } from "firebase/functions"
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

export default {
  name: 'CheckerView',
  components: {
    TableComponent
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'error-modal') {
        this.errors = []
        this.$refs.uidFileInput.reset()
      }
    })
  },
  methods: {
    readUidFile: function(input) {
      this.isWorking = true

      const fileReader = new FileReader()

      fileReader.readAsText(input.target.files[0])
      
      fileReader.onload = e => {
        this.errors = []

        const checkableData = Papa.parse(e.target.result, {
          delimiter: "",
          newline: "",
          quoteChar: '#',
          escapeChar: '##',
          header: true,
          skipEmptyLines: true,
        })

        if (checkableData.errors.length === 0) {
          this.checkableData = checkableData.data
          this.noOfUidsToCheck = checkableData.data.length
          this.noOfCheckedUids = 0
        } else {
          checkableData.errors.forEach(error => {
            const errorMsg = {
              msg: "",
              data: checkableData.data[error.row],
            }

            if (error.code === "MissingQuotes") {
              errorMsg.msg = "Fehlende Anführungszeichen"
            } else if (error.code === "UndetectableDelimiter") {
              errorMsg.msg = "Fehlender Delimiter"
            } else if (error.code === "TooFewFields") {
              errorMsg.msg = "Zu wenig Felder"
            } else if (error.code === "TooManyFields") {
              errorMsg.msg = "Zu viele Felder"
            } else {
              errorMsg.msg = ""
            }

            this.errors.push(errorMsg)
          })

          this.$bvModal.show('error-modal')
        }
      }
    },
    sendRequest: async function(element) {
      const checkVatin = httpsCallable(this.$functions, 'checkVatin')
      let result = null;
      
      await checkVatin(element)
      .then(response => {
        result = response.data;
      })
      .then(() => {
        this.noOfCheckedUids++
      })
      .catch(error => {
        console.error(error)
        result = {
          "Datum": moment().format('DD.MM.YYYY'),
          "Druck": "nein",
          "Erg_Name": "",
          "Erg_Ort": "",
          "Erg_PLZ": "",
          "Erg_Str": "",
          "ErrorCode": "500",
          "Firmenname": element.Firmenname,
          "Gueltig_ab": "0000-00-00",
          "Gueltig_bis": "0000-00-00",
          "Ort": element.Ort,
          "PLZ": element.PLZ,
          "Strasse": element.Strasse,
          "Uhrzeit": moment().format('HH:mm:ss'),
          "Ursprung": "Datei",
          "UstId_1": "DE815306628",
          "UstId_2": element.EG_Ust_ID,
          timestamp: new Date(),
        }
      })

      return result
    },
    checkNew: async function() {
      this.$bvModal.show('progress-modal')

      let responses = []

      for (let i = 0; i < this.checkableData.length; i++) {
        const response = await this.sendRequest(this.checkableData[i])
        responses.push(response)
      }

      this.negativeVerificationResults = responses.filter(result => {
        if (
          result?.Erg_Name === 'B' || 
          result?.Erg_Name === '' || 
          result?.Erg_Str === 'B' || 
          result?.Erg_Str === '' || 
          result?.Erg_PLZ === 'B' || 
          result?.Erg_PLZ === '' || 
          result?.Erg_Ort === 'B' ||
          result?.Erg_Ort === ''
        ) {
          return true
        } else {
          return false
        }
      })

      this.positiveVerificationResults = responses.filter(result => {
        if (
          result?.Erg_Name !== 'B' && 
          result?.Erg_Name !== '' && 
          result?.Erg_Str !== 'B' && 
          result?.Erg_Str !== '' && 
          result?.Erg_PLZ !== 'B' && 
          result?.Erg_PLZ !== '' && 
          result?.Erg_Ort !== 'B' &&
          result?.Erg_Ort !== '' 
        ) {
          return true
        } else {
          return false
        }
      })

      this.noOfUidsToCheck = 0
      this.noOfCheckedUids = 0
      this.checkableData = null
      this.$refs.uidFileInput.reset()
      this.saveToFile()
      window.localStorage.setItem('negativeVerificationResults', JSON.stringify(this.negativeVerificationResults))
      window.localStorage.setItem('positiveVerificationResults', JSON.stringify(this.positiveVerificationResults))
      this.$bvModal.hide('progress-modal')
    },
    saveToFile: async function() {
      const results = this.negativeVerificationResults.map(result => {
        return [
          result.Kundennummer,
          result.UstId_2,
          result.Firmenname,
          result.Ort,
          result.PLZ,
          result.Strasse,
          ''
        ]
      })

      const tsvData = Papa.unparse({
        fields: ['Kundennummer', 'EG_Ust_ID', 'Firmenname', 'Ort', 'PLZ', 'Strasse', 'Kommentar'],
        data: results
      }, {
        delimiter: "\t",
        newline: "\n",
        header: true
      })

      const blob = new Blob([tsvData], {type: 'text/tab-separated-values'})
      const dateTime = moment().format('YYYYMMDD-HHmmss')
      const fileRef = ref(this.$storage, dateTime + '-uid-export.tsv') //nach "chlid sortieren"
      const config = { contentType: 'text/tab-separated-values' }


      await uploadBytes(fileRef, blob, config)
      getDownloadURL(fileRef)
      .then(url => {
        this.downloadUrl = url
        window.localStorage.setItem('downloadUrl', url)
      })
      .catch(error => console.error("error", error))
    }
  },
  beforeMount() {
    const negativeVerificationResults = window.localStorage.getItem('negativeVerificationResults')
    if (negativeVerificationResults !== null) this.negativeVerificationResults = JSON.parse(negativeVerificationResults)

    const positiveVerificationResults = window.localStorage.getItem('positiveVerificationResults')
    if (positiveVerificationResults !== null) this.positiveVerificationResults = JSON.parse(positiveVerificationResults)
    
    const downloadUrl = window.localStorage.getItem('downloadUrl')
    if (downloadUrl !== null) this.downloadUrl = downloadUrl
  },
  data() {
    return {
      errors: [],
      noOfCheckedUids: 0,
      noOfUidsToCheck: 0,
      checkableData: null,
      negativeVerificationResults: [],
      positiveVerificationResults: [],
      downloadUrl: ''
    }
  }
}
</script>

<style>
.custom-file-input:lang(de)~.custom-file-label::after {
  content: "Durchsuchen";
}
</style>