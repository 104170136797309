<template>
  <b-navbar toggleable="sm" type="dark" variant="primary">
    <b-container class="my-1" fluid>
      <b-navbar-brand class="d-flex align-items-center py-0">
        <LogoComponent :fill="'#ffffff'" :height="'32px'" :width="'32px'" class="mr-2" />USt.-ID Checker
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" class="justify-content-between" is-nav>
        <b-navbar-nav>
          <b-nav-item :active="$route.name === 'CheckerView'" :to="{ name: 'CheckerView' }">Prüfung</b-nav-item>
          <b-nav-item :active="$route.name === 'HistoryView' || $route.name === 'HistoryResultView'" :to="{ name: 'HistoryView' }">Historie</b-nav-item>
        </b-navbar-nav>
        <b-button variant="outline-light" v-on:click="signOut"><fa-icon :icon="['fas', 'sign-out']" /> Abmelden</b-button>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
import LogoComponent from '../components/LogoComponent.vue'

export default {
  name: 'NavigationComponent',
  components: {
    LogoComponent
  },
  methods: {
    signOut: function() {
      this.$auth.signOut()
      .then(() => this.$router.replace('/'))
      .catch(error => console.error(error.message))
    }
  }
}
</script>

<style scoped>
.container-fluid {
  max-width: 1480px;
}
button:hover {
  color: #4089c9;
}
</style>